export default {
  productsCategory: null,
  productStrategia: null,
  dataProduct: null,
  titleStore: null,
  descriptionStore: null,
  outfitProduct: null,
  outfitAnalitics: null,
  productRelated: null,
  productsAnalyticsRelated: null,
  dataCatalogoAnalytics: null,
  productsAnalytics: [],
  stockCitys: null,
  productStock: null,
  deps: [],
  shippingsTotal: null,
  productWhislist: [],
  errorSendComment: false,
  //------[ Filtros ]------//
  loaderFilters: false,
  showSidebar: false,
  atajos: [],
  filtersColor: [],
  filtersPrecio: [],
  filtersSize: [],
  filtersCategoria: [],
  filtersSubcategoria: [],
  filtersDiscounts: [],
  filterFeature: [],
  allFilters: false,
  // filtersOneLevel: [],
  filtersMultiSelect: [],
  filtersOneLevel: [],
  filterCollections: [],
  colores_todo: [],
  tallas_todo: [],
  endLoadFilters: 0,
  //////////////////////////
  dataSizes: [
    {
      garmentType: 'Superior',
      titleDescription: '¿CÓMO MEDIR MI TALLA EN PRENDAS SUPERIORES?',
      description: [
        'Pecho, rodea la parte con más volumen.',
        'Cintura, rodea la parte más estrecha.',
      ],
      sizes: [
        { talla: 'XS', 'Busto (cm)': '90-92', 'Cintura (cm)': '64-66' },
        { talla: 'S', 'Busto (cm)': '94-96', 'Cintura (cm)': '68-70' },
        { talla: 'M', 'Busto (cm)': '98-100', 'Cintura (cm)': '72-74' },
        { talla: 'L', 'Busto (cm)': '102-104', 'Cintura (cm)': '76-78' },
        { talla: 'XL', 'Busto (cm)': '106-108', 'Cintura (cm)': '80-82' },
      ],
    },
    {
      garmentType: 'Inferior',
      titleDescription: '¿CÓMO MEDIR MI TALLA EN PRENDAS INFERIORES?',
      description: [
        'Cintura, rodea la parte más estrecha.',
        'Cadera, rodea la parte más ancha mientras estás de pie con los pies juntos.',
      ],
      sizes: [
        { Talla: '4', 'Cintura (cm)': '60-62', 'Cadera (cm)': '90-92' },
        { Talla: '6', 'Cintura (cm)': '64-66', 'Cadera (cm)': '94-96' },
        { Talla: '8', 'Cintura (cm)': '68-70', 'Cadera (cm)': '98-100' },
        { Talla: '10', 'Cintura (cm)': '72-74', 'Cadera (cm)': '102-104' },
        { Talla: '12', 'Cintura (cm)': '76-78', 'Cadera (cm)': '106-108' },
        { Talla: '14', 'Cintura (cm)': '80-82', 'Cadera (cm)': '110-112' },
        { Talla: '16', 'Cintura (cm)': '84-86', 'Cadera (cm)': '114-116' },
      ],
    },
    {
      garmentType: 'Zapatos',
      titleDescription: '¿CÓMO MEDIR MI TALLA EN CALZADO?',
      description: [
        'Longitud del pie: Colócate junto a una pared, con medias, con los talones junto a la pared.',
        'Coloca tu pie en una superficie dura sobre una hoja de papel blanca con el talón contra la pared.',
        'Marca con un lápiz desde tu talón hasta dónde llega tu dedo más largo.',
        'Mide la distancia de tu pie marcada en la hoja, desde el borde de la hoja hasta la punta del pie en centímetros (cm).',
      ],
      sizes: [
        { talla: '35', COL: '22.5 (cm)', US: '5', UK: '6', EU: '37 1/3' },
        { talla: '36', COL: '23 (cm)', US: '6', UK: '7.5', EU: '38' },
        { talla: '37', COL: '23.5 (cm)', US: '7', UK: '8.5', EU: '39 1/3' },
        { talla: '38', COL: '24.5 (cm)', US: '8', UK: '9.5', EU: '40 2/3' },
        { talla: '39', COL: '25 (cm)', US: '9', UK: '11', EU: '41 2/3' },
        { talla: '40', COL: '25.5 (cm)', US: '10', UK: '12', EU: '42 2/3' },
      ],
    },
  ],
}
