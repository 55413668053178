<template>
  <b-modal id="modal-register" class="m-5" centered hide-header hide-footer>
    <!-- <div class="volver-modal-login" v-if="step != 0">
            <span @click="showLoginModal()">
                &#60; Volver
            </span>
        </div> -->
    <div class="d-flex flex-wrap p-0">
      <div v-if="nextStep == 1" class="content-modal-login">
        <h4 class="mb-4">Crear cuenta</h4>
        <div class="content-inputs-login">
          <p class="text-left col-11 col-md-9">¿Cual es tu nombre?</p>
          <div class="col-11 col-md-9 position-relative">
            <input
              v-model="nombres"
              class="payment-input input-login mb-4"
              placeholder="Ej: Sofía"
              type="given-name"
              autocomplete="given-name"
            />
            <span v-if="nombres_validate" class="error-inputs"
              >Campo nombre requerido</span
            >
            <span v-if="nombres != ''" class="newlatter-error-input"
              >Nombre</span
            >
          </div>
          <p class="text-left col-11 col-md-9">
            Ingresa el correo para recibir código de acceso
          </p>
          <div class="col-11 col-md-9 position-relative">
            <input
              id="email"
              v-model="correo"
              class="payment-input input-login mb-4"
              placeholder="Correo electrónico"
              type="email"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Correo invalido</span
            >
            <span v-if="correo != ''" class="newlatter-error-input"
              >Correo electrónico</span
            >
          </div>
          <!-- <p class="col-11 col-md-9 mb-3 text-register">Datos personales</p>
                    <div class="col-11 col-md-9 position-relative">
                        <input class="payment-input input-login mb-4" placeholder="Correo electrónico" type="number"  v-model="correo">
                        <span v-if="correo_validate" class="error-inputs">Correo invalido</span>
                        <span class="newlatter-error-input" v-if="correo != ''">Código de acceso</span>
                    </div>
                    <div class="col-11 col-md-9 position-relative">
                        <input class="payment-input input-login mb-4" placeholder="Nombre" type="given-name" autocomplete="given-name"  v-model="nombres">
                        <span v-if="nombres_validate" class="error-inputs">nombre invalido</span>
                        <span class="newlatter-error-input" v-if="nombres != ''">Nombre</span>
                    </div>
                    <div class="col-11 col-md-9 position-relative">
                        <input class="payment-input input-login mb-4" placeholder="Apellidos" type="family-name"  v-model="apellidos">
                        <span v-if="apellidos_validate" class="error-inputs">Apellidos invalido</span>
                        <span class="newlatter-error-input" v-if="apellidos != ''">Apellidos</span>
                    </div>
                    <div class="col-11 col-md-9 position-relative">
                        <p class="text-register mb-3">Fecha de cumpleaños</p>
                        <div class="d-flex flex-wrap mb-3 w-100">
                            <div class="col-6">
                                <div class="position-relative me-2">
                                    <select class="payment-input w-100" style="min-height: 50px" v-model="selectDay">
                                        <option value="null">Día</option>
                                        <option :key="index" v-for="index in 31" :value="index">{{index}}</option>
                                    </select>
                                    <span class="newlatter-error-input" v-if="selectDay">Día</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="position-relative ms-2">
                                    <select class="payment-input w-100" style="min-height: 50px" v-model="selectMonth">
                                        <option value="null">Mes</option>
                                        <option :key="index2" v-for="(month, index2) in months" :value="month.value">{{month.text}}</option>
                                    </select>
                                    <span class="newlatter-error-input" v-if="selectMonth">Mes</span>
                                </div>
                            </div>
                        </div>
                        <span v-if="born_validate" class="error-inputs" style="top: auto; bottom: .9rem;">Fecha invalido</span>
                    </div>
                    <p class="text-register col-11 col-md-9 mb-3">Contraseñas</p>
                    <div class="col-11 col-md-9 position-relative">
                        <input class="payment-input input-login mb-4" placeholder="Contraseña" type="password"  v-model="password">
                        <span v-if="password_validate" class="error-inputs">Contraseña invalida</span>
                        <span class="newlatter-error-input" v-if="password != ''">Contraseña</span>
                    </div>
                    <div class="col-11 col-md-9 position-relative">
                        <input class="payment-input input-login mb-4" placeholder="Confirmar contraseña" type="password"  v-model="validatePassword">
                        <span v-if="validatePassword_validate" class="error-inputs">Las contraseñas no coinciden</span>
                        <span class="newlatter-error-input" v-if="validatePassword != ''">Confirmar contraseña</span>
                    </div>-->
        </div>
        <div class="col-11 col-md-9 d-flex align-items-center mb-5">
          <label class="d-flex">
            <input
              v-model="tyc"
              class="news-latter-checkbox"
              type="checkbox"
              checkmark="checkmark"
            />
            <span class="checkmark"></span>
          </label>
          <span
            ><p class="mb-0 text-start" style="font-size: 1rem">
              Acepto
              <router-link
                style="font-size: 1rem; color: #654062;"
                target="_blank"
                :to="{
                  name: 'Politicas',
                  params: { slug: 'politica-de-privacidad' },
                }"
                >Términos y condiciones y Política de tratamiento de
                datos.</router-link
              >
            </p></span
          >
        </div>
        <div v-if="errorRegister" class="msg-error-login">
          <p>{{ msgErrorRegister }}</p>
        </div>
        <button
          class="btn-login-modal"
          :class="!tyc ? 'btn-primary-disabled' : ''"
          :disabled="!tyc"
          @click="validateDataRegister()"
        >
          Enviar
        </button>
      </div>
      <div v-else class="content-modal-login">
        <h4 class="mb-4">Crear cuenta</h4>
        <div class="content-inputs-login">
          <p class="text-center col-11 col-md-9 mb-4">
            Hola <span class="bold">{{ nombres.split(' ')[0] }}</span> ingresa
            el código que enviamos a: <br />
            <span class="bold">{{ correo }}</span>
          </p>
          <div class="col-11 col-md-9 position-relative">
            <input
              v-model="codigo"
              class="payment-input input-login mb-3"
              placeholder="Código acceso"
              type="number"
              autocomplete="code"
            />
            <span v-if="codigo_validate" class="error-inputs"
              >Campo código requerido</span
            >
            <span v-if="codigo != ''" class="newlatter-error-input"
              >Código</span
            >
          </div>
          <p class="text-left col-11 col-md-9">Crear una nueva contraseña</p>
          <div class="col-11 col-md-9 position-relative">
            <input
              v-model="password"
              class="payment-input input-login mb-4 pe-4"
              placeholder="Ingresar clave"
              :type="typePass ? 'password' : 'text'"
            />
            <span v-if="correo_validate" class="error-inputs"
              >min. 8 caracteres</span
            >
            <span v-if="correo != ''" class="newlatter-error-input"
              >Ingresar clave</span
            >
            <svg
              class="show-password"
              xmlns="http://www.w3.org/2000/svg"
              width="24.207"
              height="23.414"
              viewBox="0 0 24.207 23.414"
              @click="typePass = !typePass"
            >
              <g
                id="Grupo_5755"
                data-name="Grupo 5755"
                transform="translate(-407 -937.793)"
              >
                <g
                  id="Grupo_5027"
                  data-name="Grupo 5027"
                  transform="translate(-1080.5 601.643)"
                >
                  <path
                    id="Trazado_2344"
                    data-name="Trazado 2344"
                    d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                    transform="translate(0)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <circle
                    id="Elipse_582"
                    data-name="Elipse 582"
                    cx="4.304"
                    cy="4.304"
                    r="4.304"
                    transform="translate(1495.196 343.37)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
                <line
                  v-if="!typePass"
                  id="Línea_1365"
                  data-name="Línea 1365"
                  y1="22"
                  x2="22"
                  transform="translate(408.5 938.5)"
                  fill="none"
                  stroke="#949494"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
          <div class="col-11 col-md-9 position-relative">
            <input
              v-model="validatePassword"
              class="payment-input input-login mb-4 pe-4"
              placeholder="Confirmar clave"
              :type="typePassConfirm ? 'password' : 'text'"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Las contraseñas no coinciden</span
            >
            <span v-if="validatePassword != ''" class="newlatter-error-input"
              >Confirmar clave</span
            >
            <svg
              class="show-password"
              xmlns="http://www.w3.org/2000/svg"
              width="24.207"
              height="23.414"
              viewBox="0 0 24.207 23.414"
              @click="typePassConfirm = !typePassConfirm"
            >
              <g
                id="Grupo_5755"
                data-name="Grupo 5755"
                transform="translate(-407 -937.793)"
              >
                <g
                  id="Grupo_5027"
                  data-name="Grupo 5027"
                  transform="translate(-1080.5 601.643)"
                >
                  <path
                    id="Trazado_2344"
                    data-name="Trazado 2344"
                    d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                    transform="translate(0)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <circle
                    id="Elipse_582"
                    data-name="Elipse 582"
                    cx="4.304"
                    cy="4.304"
                    r="4.304"
                    transform="translate(1495.196 343.37)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
                <line
                  v-if="!typePassConfirm"
                  id="Línea_1365"
                  data-name="Línea 1365"
                  y1="22"
                  x2="22"
                  transform="translate(408.5 938.5)"
                  fill="none"
                  stroke="#949494"
                  stroke-width="2"
                />
              </g>
            </svg>
          </div>
        </div>
        <div v-if="errorRegister" class="msg-error-login">
          <p>{{ msgErrorRegister }}</p>
        </div>
        <button
          class="btn-login-modal"
          :disabled="!tyc"
          @click="validateTokenRegister()"
        >
          Crear cuenta
        </button>
      </div>
      <div class="footer-modal-login">
        <img src="@/assets/icons/diamont-icon.svg" alt="decorador-diamont" />
        <div class="w-100 d-flex flex-wrap justify-content-center">
          <h4 class="mb-4 text-center w-100">
            ¿Ya tienes cuenta? Iniciar sesión
          </h4>
          <button class="btn-login-modal" @click="showLoginModal()">
            Iniciar sesión
          </button>
        </div>
      </div>
      <img
        class="btn-close-modal-product-detail"
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton de cerrar"
        @click="
          $bvModal.hide('modal-register')
          restartData()
        "
      />
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
export default {
  data() {
    return {
      correo: '',
      correo_validate: false,
      nombres: '',
      nombres_validate: false,
      apellidos: '',
      apellidos_validate: false,
      selectDay: null,
      selectMonth: null,
      born_validate: false,
      password: '',
      password_validate: false,
      validatePassword: '',
      validatePassword_validate: false,
      showMsgError: false,
      tyc: false,
      months: [
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      nextStep: 1,
      codigo: '',
      codigo_validate: false,
      typePassConfirm: true,
      typePass: true,
    }
  },
  computed: {
    ...mapState('user', ['errorRegister', 'msgErrorRegister']),
  },
  methods: {
    ...mapActions('user', ['register', 'validateCodRegister']),
    ...mapActions('cart', ['getProducts']),
    ...mapActions('products', ['getProductsFavorites']),
    async validateDataRegister() {
      /* eslint-disable */
      this.correo_validate = false
      this.nombres_validate = false
      // this.apellidos_validate = false;
      // this.born_validate = false;
      // this.password_validate = false;
      // this.validatePassword_validate = false;
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (
        re.test(this.correo) &&
        this.nombres.length > 4
        // && this.password.length > 4 && this.validatePassword == this.password &&
        // this.apellidos.length > 4 && this.selectDay && this.selectMonth
      ) {
        await this.register({
          correo: this.correo,
          nombres: this.nombres,
          // apellidos: this.apellidos,
          // password: this.password,
          // password_confirmed: this.validatePassword,
          // hb_month: this.selectMonth,
          // hb_day: this.selectDay < 10 ? '0' + this.selectDay : this.selectDay,
          // newsletter: true,
          // tokenCart: window.localStorage.getItem('tokenCart')
        }).then(() => {
          if (this.errorRegister) {
            this.showMsgError = true
          } else {
            this.nextStep = 2
            // DK-SignUp
            // window.dataLayer.push({
            //   event: 'DK-SignUp',
            //   name: this.nombres,
            //   email: this.correo,
            // })
            // document.cookie = 'email=' + this.correo;
            // this.$bvModal.hide('modal-register');
            // this.restartData();
            // this.getProducts();
            // this.getProductsFavorites();
          }
        })
      } else {
        this.correo_validate = !re.test(this.correo)
        this.nombres_validate = !(this.nombres.length > 4)
        // this.password_validate = !(this.password.length > 0);
        // this.apellidos_validate = !(this.apellidos.length > 4);
        // this.born_validate = !(this.selectDay && this.selectMonth);
        // this.validatePassword_validate = !(this.password == this.validatePassword);
      }
    },
    async validateTokenRegister() {
      if (
        this.password.length >= 8 &&
        this.password == this.validatePassword &&
        this.codigo.length > 4
      ) {
        await this.validateCodRegister({
          correo: this.correo,
          password: this.password,
          passwordConfirm: this.validatePassword,
          codigo: this.codigo,
        }).then(() => {
          if (this.errorRegister) {
            this.showMsgError = true
          } else {
            this.$router.push({ name: 'Profile', params: { isRegister: true } })
            this.$bvModal.hide('modal-register')
            this.restartData()
            GA4SignUp('register derek')
          }
        })
      } else {
        this.password_validate = !(this.password.length > 0)
        this.validatePassword_validate = !(
          this.password == this.validatePassword
        )
        this.codigo_validate = !this.codigo.length > 4
      }
    },
    restartData() {
      this.correo = ''
      this.password = ''
      this.$store.state.user.errorRegister = false
    },
    showLoginModal() {
      this.$bvModal.hide('modal-register')
      this.$bvModal.show('modal-login')
    },
  },
}
</script>
