<template>
  <div v-if="displayBtnHelp" id="btn-help" class="button-help">
    <div
      class="button-show-help"
      :class="
        $route.name == 'ProductDetail' ? 'is-product-detail-movil-btn' : ''
      "
    >
      <a
        class="d-flex align-items-center"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=573232747474&text=¡Hola! Estoy interesado(a) en comprar."
      >
        <div class="img-background-btn-help p-2">
          <div
            style="height: 40px; width: 40px;"
            class="d-flex justify-content-center align-items-center"
          >
            <img
              width="30"
              height="30"
              src="@/assets/icons/icon-whatsapp-white.svg"
            />
          </div>
        </div>
      </a>
      <a
        class="text-decoration-none"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=573232747474&text=¡Hola! Estoy interesado(a) en comprar."
        @click="open = false"
      >
        <div
          class="container-text-help"
          :style="
            closeText ? 'opacity: 1; top: -3.2rem;' : 'opacity: 0; top: 2rem;'
          "
        >
          ¿Necesitas ayuda?
        </div>
      </a>
      <div
        :style="
          closeText ? 'opacity: 1; top: -2.7rem;' : 'opacity: 0; top: 2rem;'
        "
        class="close-text-help"
        @click="closeText = false"
      >
        x
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      open: false,
      closeText: false,
      showButton: false,
      name: '',
      tel: '',
      timeCall: '',
      tyc: false,
      success: '',
      validateName: false,
      validateTel: false,
      validateTime: false,
      validateTYC: false,
      loading: false,
      hours: [
        { text: '08:00 am a 09:00 am', desde: 8, hasta: 9 },
        { text: '09:00 am a 10:00 am', desde: 9, hasta: 10 },
        { text: '10:00 am a 11:00 am', desde: 10, hasta: 11 },
        { text: '11:00 am a 12:00 pm', desde: 11, hasta: 12 },
        { text: '12:00 pm a 01:00 pm', desde: 12, hasta: 13 },
        { text: '01:00 pm a 02:00 pm', desde: 13, hasta: 14 },
        { text: '02:00 pm a 03:00 pm', desde: 14, hasta: 15 },
        { text: '03:00 pm a 04:00 pm', desde: 15, hasta: 16 },
        { text: '04:00 pm a 05:00 pm', desde: 16, hasta: 17 },
        { text: '05:00 pm a 06:00 pm', desde: 17, hasta: 18 },
      ],
      dias: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      selectDay: '',
      sendSoli: false,
      showModal: false,
    }
  },
  computed: {
    ...mapState(['displayBtnHelp']),
    showChatInfoBib() {
      console.log(this.open) //no borrar, es la variable que me actualiza la propiedad
      let date = new Date()
      let show = false
      if (date.getDay() == 0) {
        if (date.getHours() >= 9 && date.getHours() < 18) {
          show = true
        }
      } else {
        if (
          (date.getHours() >= 8 && date.getMinutes() > 30) ||
          date.getHours() > 8
        ) {
          if (
            (date.getHours() <= 18 && date.getMinutes() < 30) ||
            date.getHours() < 18
          ) {
            show = true
          }
        }
      }
      return show
    },
    diasHabiles() {
      let array = []
      let tempDate = new Date()
      if (tempDate.getHours() >= 16) tempDate.setDate(tempDate.getDate() + 1)
      for (let index = 0; index < 4; index++) {
        let now = new Date()
        now.setDate(tempDate.getDate() + index)
        let objectDay = {
          dia: this.dias[now.getDay()],
          nDia: now.getDate() > 9 ? now.getDate() : '0' + now.getDate(),
          mes: this.months[now.getMonth()],
          disabled: now.getDay() == 0,
          value:
            now.getFullYear() +
            '-' +
            (now.getMonth() + 1) +
            '-' +
            now.getDate(),
        }
        array.push(objectDay)
      }
      return array
    },
    validateDiary() {
      if (this.selectDay) {
        if (parseInt(this.selectDay.nDia) == new Date().getDate()) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    validateNameContact: function() {
      const re = /^(?!.* $)[A-Za-z ]{3,}/
      return re.test(this.name)
    },
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  watch: {
    selectDay() {
      this.timeCall = ''
    },
    showModal() {
      this.sendSoli = false
      this.selectDay = ''
      this.name = ''
      this.tel = ''
      this.timeCall = ''
      this.validateName = false
      this.validateTel = false
      this.validateTime = false
      this.validateTYC = false
    },
  },
  mounted() {
    window.addEventListener('scroll', this.visibleFooter)
    setTimeout(() => {
      this.closeText = true
    }, 10000)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.visibleFooter)
  },
  methods: {
    visibleFooter() {
      var element = document.querySelector('#footer')
      if (element) {
        var positionFooter = element.getBoundingClientRect()
        if (positionFooter.top <= window.innerHeight) {
          document.getElementById('btn-help').style.display = 'none'
        } else {
          document.getElementById('btn-help')
            ? (document.getElementById('btn-help').style.display = 'flex')
            : ''
        }
      }
    },
    toggleOpen: function() {
      this.open = !this.open
    },
    closePopoverShowModal: function() {
      this.open = false
      this.showModal = true
      this.showChat()
    },
    showChat() {
      $('.ib-widget-wrapper').css({
        visibility: 'visible',
        'z-index': '1000000',
      })
    },
    clickOutside() {
      this.showButton = false
    },
    async sendHourContact() {
      /* eslint-disable */
      if (
        this.name &&
        this.validatePhone &&
        this.tyc &&
        this.timeCall &&
        this.selectDay
      ) {
        this.validateName = false
        this.validateTel = false
        this.validateTime = false
        this.validateTYC = false
        this.loading = true
        this.$recaptchaLoaded().then(async () => {
          this.$recaptcha('login').then(async token => {
            if (token) {
              let data = new FormData()
              data.append('nombre', this.name)
              data.append('celular', this.tel)
              data.append('hora_contacto', this.timeCall)
              data.append('fecha', this.selectDay.value)
              await this.axios({
                method: 'POST',
                headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                },
                url: '/contact/form/help',
                data: data,
              })
                .then(response => {
                  this.success = response.data.message
                  this.name = ''
                  this.tel = ''
                  this.timeCall = ''
                  this.tyc = false
                  // this.$gtag.event('Onsubmit', {
                  //   event: 'formulario',
                  //   event_category: 'suscripcion',
                  //   event_label: 'blog',
                  //   value: 0,
                  // })
                  this.sendSoli = true
                })
                .catch(err => {
                  console.log(err.response.data)
                })
              this.loading = false
            }
          })
        })
      } else {
        this.validateName = !this.validateNameContact
        this.validateTel = !this.validatePhone
        this.validateTime = !this.timeCall
        this.validateTYC = !this.tyc
      }
    },
  },
}
</script>
