import Vue from 'vue'
import $store from '../../store/index'
import $router from '@/router'

export async function getProductsAnonymous({ state }, payload) {
  $store.loaderDerek = true
  var form = new FormData()
  form.append('token', payload.token)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/get_products',
    data: form,
  })
    .then(function(response) {
      state.dataCartAnonymous = response.data
    })
    .catch(e => {
      console.log(e)
      let message = ''
      for (const property in e.response.data.errors) {
        message = message + e.response.data.errors[property] + ' '
        if (property == 'token') {
          $router.replace({ name: 'Home' })
        }
      }
    })
    .finally(() => {
      $store.loaderDerek = false
      console.log('getProduct CartAnonymous Done')
    })
}

export async function updateEmailCartAnonymous({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  form.append('cliente_correo', payload.correo)
  form.append('step', 1)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/updateInfoCart',
    data: form,
  })
    .then(function(response) {
      state.dataCartAnonymous = response.data
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('Update Email Done')
    })
}

export async function updateInfoCartAnonymous({ state }, payment) {
  var form = new FormData()
  form.append('token', payment.token)
  form.append('cliente_correo', state.dataCartAnonymous.carrito.cliente_correo)
  form.append(
    'cliente_celular',
    state.dataCartAnonymous.carrito.cliente_celular,
  )
  form.append('cliente_nombre', state.dataCartAnonymous.carrito.cliente_nombre)
  form.append(
    'cliente_apellidos',
    state.dataCartAnonymous.carrito.cliente_apellidos,
  )
  form.append('tipodoc', state.dataCartAnonymous.carrito.tipodoc)
  form.append(
    'cliente_documento',
    state.dataCartAnonymous.carrito.cliente_documento,
  )
  form.append(
    'cliente_direccion',
    state.dataCartAnonymous.carrito.cliente_direccion,
  )
  form.append(
    'cliente_departamento',
    state.dataCartAnonymous.carrito.cliente_departamento,
  )
  form.append('cliente_ciudad', state.dataCartAnonymous.carrito.cliente_ciudad)
  form.append('cliente_barrio', state.dataCartAnonymous.carrito.cliente_barrio)
  form.append('direccion_id', state.dataCartAnonymous.carrito?.idDir)
  form.append('observaciones', state.dataCartAnonymous.carrito?.observaciones)
  form.append('step', payment.step)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/updateInfoCart',
    data: form,
  })
    .then(function(response) {
      state.dataCartAnonymous = response.data
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('updateInfoData Done')
    })
}

export async function PaymentUponDelibery({ state }, payment) {
  var form = new FormData()
  form.append('token', payment.token)
  form.append('pedido_fe_dian', payment.esFE)
  state.msgErrorCheckout = ''
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/payment/orderAgainstDelivery',
    data: form,
  })
    .then(function(response) {
      state.dataSummaryPayment = response.data.response
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'controEntrega'
      } else {
        let message = ''
        if (e.response.data.message) {
          message = e.response.data.message
        } else {
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
        }
        state.msgErrorCheckout = message
      }
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

export async function removeBono({ state }, payment) {
  let data = new FormData()
  $store.state.loaderDerek = true
  data.append('token', payment.token)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/delete_giftcard',
    data: data,
  })
    .then(response => {
      state.dataCartAnonymous = response.data
      $store.state.loaderDerek = false
    })
    .catch(e => {
      let message = ''
      $store.state.loaderDerek = false
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.errors) {
          message = message + e.response.errors[property] + ' '
        }
      }
    })
}

export async function payWhitGiftCard({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  state.msgErrorCheckout = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/payment/giftcard',
    data: form,
  })
    .then(function(response) {
      state.dataSummaryPayment = response.data.response
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'bonoRegalo'
      } else {
        let message = ''
        if (e.response.data.message) {
          message = e.response.data.message
        } else {
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
        }
        state.msgErrorCheckout = message
      }
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

export async function paymentEfectyBaloto({ state }, payload) {
  state.errorPaymentBillBalotoEfecty = ''
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  var form = new FormData()
  form.append('token', payload.token)
  form.append('ticketId', payload.typePago)
  form.append('pedido_fe_dian', payload.esFE)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/payu/chargeEfectivo',
    data: form,
  })
    .then(function(response) {
      state.dataSummaryPayment = response.data.response
      state.errorPaymentBillBalotoEfecty = ''
      window.open(
        response.data.response.pedido.pedido.transaccion
          .transaccion_urlefectybaloto,
        '_blank',
      )
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'efectyBaloto'
      } else {
        let message = ''
        if (e.response.data.message) {
          message = e.response.data.message
        } else {
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
        }
        state.errorPaymentBillBalotoEfecty = message
      }
    })
    .finally(() => {
      console.log('Payment Efecty-Via Done')
    })
}

export async function payPSE({ state }, payload) {
  var form = new FormData()
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  form.append('token', payload.token)
  form.append('docFinancialInstitution', payload.codPSE)
  state.errorPSE = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/payu/chargePSE',
    data: form,
  })
    .then(response => {
      state.urlPayPSE = response.data.response.url
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'pse'
      } else {
        state.errorPSE = e.response.data.response.msg
      }
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

export async function paymentCreditcard({ state }, payload) {
  var form = new FormData()
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  form.append('token', payload.token)
  form.append('paymentMethodID', payload.paymentMethodID)
  form.append('securityCode', payload.securityCode)
  form.append('cardExpirationYear', payload.cardExpirationYear)
  form.append('cardholderName', payload.cardholderName)
  form.append('docNumber', payload.docNumber)
  form.append('cardNumber', payload.cardNumber)
  form.append('cardExpirationMonth', payload.cardExpirationMonth)
  form.append('docType', payload.docType)
  form.append('installments', payload.installments)
  form.append('pedido_fe_dian', payload.esFE)
  state.errorCreditCard = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/payu/credit_card_charge',
    data: form,
  })
    .then(response => {
      state.dataSummaryPayment = response.data.response
      state.errorPaymentBillBalotoEfecty = false
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'creditCard'
      } else {
        state.errorCreditCard = e.response.data.response.msg
      }
    })
    .finally(() => {
      console.log('payment-credit-card done')
    })
}

export async function AddBonoGift({ state }, payload) {
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('codigo', payload.codigo)
  form.append('token', payload.token)
  state.msgErrorCheckout = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/apply_giftcard',
    data: form,
  })
    .then(function(response) {
      state.dataCartAnonymous = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCheckout = message
      $store.state.loaderDerek = false
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('Apply Bono')
    })
}

export async function validateDocCupo({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/cupopersonal/validarDocumentoClienteCupo',
    data: form,
  })
    .then(response => {
      state.dataCupo = response.data.response.data_form
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('validateDocCupo Done')
    })
}

export async function resendCodCupoCall({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/comunications/transactional/reenviarTokenVoz',
    data: form,
  })
    .then(() => {
      console.log('')
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}

export async function resendCodCupoWPP({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/comunications/transactional/reenviarTokenWhatsApp',
    data: form,
  })
    .then(() => {
      console.log('')
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}

export async function resendCodCupoSMS({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/comunications/transactional/reenviarTokenSMS',
    data: form,
  })
    .then(() => {
      console.log('')
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}

export async function validateTokenCupo({ state }, payload) {
  var form = new FormData()
  form.append('token', payload.token)
  form.append('documento', payload.doc)
  form.append('cupoCodigoSMS', payload.cupoCodigoSMS)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/cupopersonal/validateToken',
    data: form,
  })
    .then(response => {
      state.dataSummaryPayment = response.data.response
      state.errorPaymentBillBalotoEfecty = false
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}

export async function RegisterPollCheckout({ state }, payload) {
  var form = new FormData()
  form.append('pedido_id', payload.pedido_id)
  form.append('puntaje', payload.puntaje)
  form.append('mensaje', payload.mensaje)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/order/survey/send',
    data: form,
  })
    .then(() => {
      console.log(state != '')
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('Finish!!! Done')
    })
}

export async function applyCupon({ state }, payload) {
  var form = new FormData()
  form.append('cupon_codigo', payload.discountCode)
  form.append('token', payload.token)
  state.msgErrorCupon = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/applyCoupon',
    data: form,
  })
    .then(function(response) {
      state.dataCart = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCupon = message
    })
    .finally(() => {
      console.log('getProduct Cart Done')
    })
}

export async function applyCuponAnonymous({ state }, payload) {
  var form = new FormData()
  form.append('cupon_codigo', payload.discountCode)
  form.append('token', payload.token)
  state.msgErrorCupon = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/applyCoupon',
    data: form,
  })
    .then(function(response) {
      state.dataCartAnonymous = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCupon = message
    })
    .finally(() => {
      console.log('getProduct Cart Done')
    })
}

export async function removeCupon({ state }, payload) {
  let data = new FormData()
  data.append('token', payload.token)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/removeCoupon',
    data: data,
  })
    .then(response => {
      state.dataCart = response.data.carrito
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.errors) {
          message = message + e.response.errors[property] + ' '
        }
      }
      console.log(message)
    })
}

export async function removeCuponAnonymo({ state }, payload) {
  let data = new FormData()
  data.append('token', payload.token)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/removeCoupon',
    data: data,
  })
    .then(response => {
      state.dataCartAnonymous = response.data
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.errors) {
          message = message + e.response.errors[property] + ' '
        }
      }
      console.log(message)
    })
}

export function changeEsFE({ commit }, newVal) {
  commit('setEsFE', newVal)
}
