import Vue from 'vue'
import {
  BFormRating,
  BPagination,
  BSkeletonImg,
  BSkeleton,
  NavPlugin,
  SidebarPlugin,
  BPopover,
  BSidebar,
  ModalPlugin,
  FormGroupPlugin,
  TablePlugin,
  PopoverPlugin,
  VBHoverPlugin,
  ImagePlugin,
  BImg,
  CardPlugin,
  BCard,
  NavbarPlugin,
  BNavbar,
  BTable,
  FormCheckboxPlugin,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(NavPlugin)
Vue.use(CardPlugin)
Vue.use(ImagePlugin)
Vue.use(ModalPlugin)
Vue.use(TablePlugin)
Vue.use(NavbarPlugin)
Vue.use(PopoverPlugin)
Vue.use(VBHoverPlugin)
Vue.use(SidebarPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.component('b-img', BImg)
Vue.component('b-card', BCard)
Vue.component('b-table', BTable)
Vue.component('b-navbar', BNavbar)
Vue.component('b-sidebar', BSidebar)
Vue.component('b-popover', BPopover)
Vue.component('b-skeleton', BSkeleton)
Vue.component('b-pagination', BPagination)
Vue.component('b-form-rating', BFormRating)
Vue.component('b-skeleton-img', BSkeletonImg)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
