export default {
  dataCartAnonymous: null,
  dataSummaryPayment: null,
  msgModalExcepcion406: '',
  btnModalExcepcion406: '',
  msgErrorCheckout: '',
  errorPaymentBillBalotoEfecty: '',
  errorCreditCard: '',
  dataCupo: null,
  errorCupo: false,
  msgErrorCupo: '',
  errorPSE: '',
  msgErrorCupon: '',
  urlPayPSE: '',
  faltanCampos: false,
  esFE: '0',
}
