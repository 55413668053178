export default {
  dataUser: null,
  infoClient: null,
  errorLogin: false,
  msgError: '',
  errorRegister: false,
  msgErrorRegister: '',
  errorUpdatePerfil: false,
  msgErrorUpdatePerfil: '',
  errorUpdatePassPerfil: false,
  msgErrorUpdatePassPerfil: '',
  directions: null,
  errorDirection: false,
  orders: {
    actives: [],
  },
  dataOrder: null,
  errorOrder: false,
  msgErrorOrder: '',
  errorNewsLatter: false,
  msgerrorNewsLatter: '',
  /////////////////////////////
  /////// Datos de Cupo ///////
  /////////////////////////////
  dataCupo: null,
  errorCupo: false,
  msgErrorCupo: '',
  puede_abonar: 0,
  discount: 0,
  facturas: [],
  messageSendCode: null,
  msgErrorValidateCod: '',
  productos_error: null,
  status_devolucion: false,
  tituloNewslatter: 'Regístrate en nuestro newslatter',
  descripcionNewslatter: 'Descubre tendencias, promociones y mucho más',
}
