<template>
  <b-modal
    id="modal-login"
    v-model="modalLogin"
    class="m-5"
    centered
    hide-header
    hide-footer
  >
    <div v-if="step != 0" class="volver-modal-login">
      <span @click="step = 0">
        &#60; Volver
      </span>
    </div>
    <div class="d-flex flex-wrap p-0">
      <div v-if="step == 0" class="content-modal-login">
        <h4 class="mb-4">Iniciar sesión</h4>
        <p
          class="col-10 col-md-9 fs-6 text-center"
          :class="clickBtnFavorite ? 'mb-2' : 'mb-4'"
        >
          Elige una de las opciones para confirmar tu identidad.
        </p>
        <p
          v-if="clickBtnFavorite"
          class="col-10 col-md-9 mb-4 fs-6 text-center"
        >
          Debes iniciar sesión o regístrate para agregar productos a tu lista de
          favoritos.
        </p>
        <div class="content-inputs-login">
          <div class="col-10 col-md-9 position-relative">
            <button class="btn-primary my-3 w-100" @click="step = 1">
              Ingresar con mail y contraseña
            </button>
          </div>
          <div class="col-10 col-md-9 position-relative">
            <button class="secondary-button my-4 w-100" @click="step = 3">
              Recibir clave de acceso por correo
            </button>
          </div>
        </div>
      </div>
      <div v-if="step == 1" class="content-modal-login">
        <h4 class="mb-4">Iniciar sesión</h4>
        <div class="content-inputs-login">
          <div class="col-10 col-md-9 position-relative">
            <input
              id="email"
              v-model="correo"
              class="payment-input input-login"
              placeholder="Correo electrónico"
              type="email"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Correo invalido</span
            >
            <span v-if="correo != ''" class="newlatter-error-input"
              >Correo electrónico</span
            >
          </div>
          <div class="col-10 col-md-9 position-relative">
            <input
              v-model="password"
              class="payment-input input-login mb-4 pe-5"
              placeholder="Contraseña"
              autocomplete="current-password"
              :type="typePass ? 'password' : 'text'"
            />
            <span v-if="password_validate" class="error-inputs"
              >Contraseña invalida</span
            >
            <span v-if="password != ''" class="newlatter-error-input"
              >Contraseña</span
            >
            <svg
              class="show-password"
              xmlns="http://www.w3.org/2000/svg"
              width="24.207"
              height="23.414"
              viewBox="0 0 24.207 23.414"
              @click="typePass = !typePass"
            >
              <g
                id="Grupo_5755"
                data-name="Grupo 5755"
                transform="translate(-407 -937.793)"
              >
                <g
                  id="Grupo_5027"
                  data-name="Grupo 5027"
                  transform="translate(-1080.5 601.643)"
                >
                  <path
                    id="Trazado_2344"
                    data-name="Trazado 2344"
                    d="M1510.5,347.674s-3.826,7.174-11,7.174-11-7.174-11-7.174,3.826-7.174,11-7.174S1510.5,347.674,1510.5,347.674Z"
                    transform="translate(0)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <circle
                    id="Elipse_582"
                    data-name="Elipse 582"
                    cx="4.304"
                    cy="4.304"
                    r="4.304"
                    transform="translate(1495.196 343.37)"
                    fill="none"
                    stroke="#949494"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
                <line
                  v-if="!typePass"
                  id="Línea_1365"
                  data-name="Línea 1365"
                  y1="22"
                  x2="22"
                  transform="translate(408.5 938.5)"
                  fill="none"
                  stroke="#949494"
                  stroke-width="2"
                />
              </g>
            </svg>
            <div class="d-flex justify-content-end">
              <a class="text-remember-password" @click="step = 2"
                >Recordar contraseña</a
              >
            </div>
          </div>
        </div>
        <div v-if="errorLogin" class="msg-error-login">
          <p>{{ msgError }}</p>
        </div>
        <button
          class="btn-primary btn-login-modal"
          @click="loaderDerek ? '' : validateDataLogin()"
        >
          Ingresar
        </button>
      </div>
      <div
        v-else-if="step == 2 && stepRememberPass == 1"
        class="content-modal-login"
      >
        <h4 class="mb-4">Restablecer contraseña</h4>
        <p class="mb-4 fs-6">
          Escribe tu correo electrónico para solicitar clave de acceso
        </p>
        <div class="content-inputs-login">
          <div class="col-10 col-md-9 position-relative">
            <input
              id="email"
              v-model="correoRememberPass"
              class="payment-input input-login"
              placeholder="Correo electrónico"
              type="email"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Correo invalido</span
            >
            <span v-if="correoRememberPass != ''" class="newlatter-error-input"
              >Correo electrónico</span
            >
          </div>
        </div>
        <div v-if="errorLogin" class="msg-error-login">
          <p>{{ msgError }}</p>
        </div>
        <button
          class="btn-primary btn-login-modal"
          @click="loaderDerek ? '' : sendCodRememberPass()"
        >
          Ingresar
        </button>
      </div>
      <div
        v-else-if="step == 2 && stepRememberPass == 2"
        class="content-modal-login"
      >
        <h4 class="mb-4">Cambiar contraseña</h4>
        <p class="mb-4 text-center col-10 fs-6">
          Escribe tu correo electrónico para solicitar clave de acceso hemos
          enviado un código de seguridad a tu correo
        </p>
        <div class="content-inputs-login">
          <div class="col-10 col-md-9 position-relative">
            <input
              v-model="codRememberPass"
              class="payment-input input-login mb-4"
              placeholder="Código"
              type="number"
              name="text"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Código invalido</span
            >
            <span v-if="codRememberPass != ''" class="newlatter-error-input"
              >Código</span
            >
          </div>
          <div class="col-10 col-md-9 position-relative">
            <input
              v-model="passwordRemember"
              class="payment-input input-login mb-4"
              placeholder="Contraseña"
              type="password"
              name="text"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span v-if="passwordRemember != ''" class="newlatter-error-input"
              >Contraseña</span
            >
          </div>
          <div class="col-10 col-md-9 position-relative">
            <input
              v-model="confirmPasswordRemember"
              class="payment-input input-login mb-4"
              placeholder="Confirmar contraseña"
              type="password"
            />
            <span v-if="correo_validate" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span
              v-if="confirmPasswordRemember != ''"
              class="newlatter-error-input"
              >Confirmar contraseña</span
            >
          </div>
        </div>
        <div v-if="errorLogin" class="msg-error-login">
          <p>{{ msgError }}</p>
        </div>
        <button
          class="btn-primary btn-login-modal"
          @click="loaderDerek ? '' : changePassword()"
        >
          Ingresar
        </button>
      </div>
      <div v-if="step == 3" class="content-modal-login">
        <h4 class="mb-4">Iniciar sesión</h4>
        <div v-if="stepCodeToEmail == 1" class="content-modal-login">
          <p class="mb-4 fs-6 px-3 text-center">
            Escribe tu correo electrónico para solicitar clave de acceso.
          </p>
          <div class="content-inputs-login">
            <div class="col-10 col-md-9 position-relative">
              <input
                id="email"
                v-model="emailSendCode"
                class="payment-input input-login"
                placeholder="Correo electrónico"
                type="email"
              />
              <span v-if="correo_validate_code" class="error-inputs"
                >Correo invalido</span
              >
              <span v-if="emailSendCode != ''" class="newlatter-error-input"
                >Correo electrónico</span
              >
            </div>
          </div>
          <div v-if="errorLogin" class="msg-error-login">
            <p>{{ msgError }}</p>
          </div>
          <button
            class="btn-primary btn-login-modal"
            @click="loaderDerek ? '' : sendCodeEmail(0)"
          >
            Continuar
          </button>
        </div>
        <div v-if="stepCodeToEmail == 2" class="content-modal-login">
          <p class="mb-4 fs-6 px-3 text-center">
            Hemos enviado un código de acceso a tu correo electrónico.
          </p>
          <div class="content-inputs-login">
            <div class="col-10 col-md-9 position-relative">
              <input
                v-model="codeEmailLogin"
                class="payment-input input-login"
                placeholder="Código de acceso"
                type="number"
              />
              <span v-if="code_validate_email" class="error-inputs"
                >Correo invalido</span
              >
              <span v-if="codeEmailLogin != ''" class="newlatter-error-input"
                >Código de acceso</span
              >
            </div>
          </div>
          <div v-if="errorLogin" class="msg-error-login">
            <p>{{ msgError }}</p>
          </div>
          <button
            class="btn-primary btn-login-modal"
            @click="loaderDerek ? '' : validateCodeEmailAndLogin()"
          >
            Ingresar
          </button>
          <div class="w-100">
            <p v-if="countdown">{{ messageReSend }}</p>
            <p
              v-else
              class="text-remember-password"
              @click="loaderDerek ? '' : sendCodeEmail(1)"
            >
              Reenviar código
            </p>
          </div>
        </div>
      </div>
      <div v-if="$route.name != 'Payment'" class="footer-modal-login">
        <img src="@/assets/icons/diamont-icon.svg" alt="decorador-diamont" />
        <div class="w-100 d-flex flex-wrap justify-content-center">
          <h4 class="mb-4 text-center w-100">¿Eres nueva? Regístrate</h4>
          <button
            class="btn-primary btn-login-modal"
            @click="showRegisterModal()"
          >
            Registrarme
          </button>
        </div>
      </div>
      <img
        class="btn-close-modal-product-detail"
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton de cerrar"
        @click="
          $bvModal.hide('modal-login')
          restartData()
        "
      />
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4Login } from '@/GA4/events'
export default {
  data() {
    return {
      modalLogin: false,
      correo: '',
      correo_validate: false,
      password: '',
      password_validate: false,
      showMsgError: false,
      step: 0,
      stepRememberPass: 1,
      correoRememberPass: '',
      codRememberPass: '',
      passwordRemember: '',
      confirmPasswordRemember: '',
      validateCod: false,
      validatePassWord: false,
      validateConfirmPassword: false,
      correo_validate_code: false,
      code_validate_email: false,
      emailSendCode: '',
      codeEmailLogin: '',
      stepCodeToEmail: 1,
      typePass: true,
      messageReSend: '',
      countdown: 0,
    }
  },
  computed: {
    ...mapState(['clickBtnFavorite']),
    ...mapState('user', ['errorLogin', 'msgError', 'loaderDerek']),
  },
  watch: {
    /* eslint-disable */
    modalLogin() {
      if (!this.modalLogin) {
        this.$store.state.clickBtnFavorite = false
        this.restartData()
      }
    },
    correo() {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (re.test(this.correo)) {
        this.correoRememberPass = this.correo
      }
    },
  },
  methods: {
    ...mapActions('user', [
      'login',
      'sendCodLogin',
      'changePasswordCode',
      'sendCodLoginToEmail',
      'sendCodLoginFromEmail',
      'sendCodLoginFromEmail',
    ]),
    ...mapActions('cart', ['getProducts']),
    ...mapActions('products', ['getProductsFavorites']),
    async sendCodeEmail(isReSend) {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (re.test(this.emailSendCode)) {
        await this.sendCodLoginToEmail({ correo: this.emailSendCode, isReSend: isReSend }).then(
          () => {
            if (this.errorLogin) {
              this.showMsgError = true
            } else {
              this.startCounter(120)
              this.stepCodeToEmail = 2
            }
          },
        )
      } else {
        this.correo_validate_code = !re.test(this.emailSendCode)
      }
    },
    startCounter(time) {
      if (time > 0) {
        this.countdown = true
        let minutes = parseInt(time / 60).toString()
        let seconds = (time - (parseInt(minutes) * 60)).toString()
        this.messageReSend = `Reenviar código en ${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
        setTimeout(() => {
          this.startCounter(time - 1)
        }, 1000);
      } else {
        this.countdown = false
        this.messageReSend = ''
      }
    },
    async validateCodeEmailAndLogin() {
      if (this.codeEmailLogin != '') {
        await this.sendCodLoginFromEmail({
          correo: this.emailSendCode,
          code: this.codeEmailLogin,
        }).then(() => {
          if (this.errorLogin) {
            this.showMsgError = true
          } else {
            GA4Login('Login derek')
            document.cookie = 'email=' + this.emailSendCode
            this.$bvModal.hide('modal-login')
            this.restartData()
            this.getProducts()
            this.getProductsFavorites()
          }
        })
      } else {
        this.code_validate_email = !(this.codeEmailLogin != '')
      }
    },
    async validateDataLogin() {
      this.correo_validate = false
      this.password_validate = false
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (re.test(this.correo) && this.password.length > 0) {
        await this.login({ correo: this.correo, password: this.password }).then(
          () => {
            if (this.errorLogin) {
              this.showMsgError = true
            } else {
              GA4Login('Login derek')
              document.cookie = 'email=' + this.correo
              this.$bvModal.hide('modal-login')
              this.restartData()
              this.getProducts()
              this.getProductsFavorites()
            }
          },
        )
      } else {
        this.correo_validate = !re.test(this.correo)
        this.password_validate = !this.password.length > 0
      }
    },
    restartData() {
      this.correo = ''
      this.password = ''
      this.$store.state.user.errorLogin = false
      this.step = 0
      this.stepRememberPass = 1
      this.correoRememberPass = ''
      this.codRememberPass = ''
      this.passwordRemember = ''
      this.confirmPasswordRemember = ''
      this.correo_validate = false
      this.password_validate = false
      this.correo_validate_code = false
      this.code_validate_email = false
      this.emailSendCode = ''
      this.codeEmailLogin = ''
      this.stepCodeToEmail = 1
    },
    showRegisterModal() {
      this.$bvModal.hide('modal-login')
      this.$bvModal.show('modal-register')
    },
    async sendCodRememberPass() {
      /* eslint-disable */
      this.correo_validate = false
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (re.test(this.correoRememberPass)) {
        await this.sendCodLogin({ email: this.correoRememberPass }).then(() => {
          if (!this.errorLogin) {
            this.stepRememberPass = 2
          }
        })
      } else {
        this.correo_validate = true
      }
    },
    async changePassword() {
      if (
        this.codRememberPass &&
        this.passwordRemember.length > 4 &&
        this.passwordRemember == this.confirmPasswordRemember
      ) {
        await this.changePasswordCode({
          correo: this.correoRememberPass,
          password: this.passwordRemember,
          passwordConfirm: this.confirmPasswordRemember,
          cod: this.codRememberPass,
        }).then(() => {
          if (this.errorLogin) {
            this.showMsgError = true
          } else {
            document.cookie = 'email=' + this.correoRememberPass
          }
        })
      } else {
        this.validateCod = !this.codRememberPass
        this.validatePassWord = !(this.passwordRemember.length > 4)
        this.validateConfirmPassword = !(
          this.passwordRemember == this.confirmPasswordRemember
        )
      }
    },
  },
}
</script>
