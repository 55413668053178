<template>
  <b-modal id="modalcupo" v-model="showModal" centered hide-footer>
    <div v-if="stepSoli == 0" class="container-modal-soli-cupo">
      <img
        src="@/assets/icons/mediosPago/quac.svg"
        alt="icono quac"
        class="mb-3"
        style="max-width: 100px"
      />
      <p class="title-modal-soli-cupo">Bienvenido a Quac!</p>
      <p class="text-center">Por favor ingresa tu número de documento.</p>
      <div class="form-group">
        <label class="form-group__label" for="">Número de documento</label>
        <div class="input-group">
          <div class="input-group__prepend">
            <span class="input-group__text">
              CC
            </span>
          </div>
          <input v-model="documento" class="input" type="text" maxlength="10" />
        </div>
      </div>
      <div class="form-group-checkbox form__legal mt-4">
        <div class="input-checkbox">
          <input
            id="accepted_terms"
            v-model="checkboxTerms"
            name="accepted_terms"
            type="checkbox"
          />
        </div>
        <p class="form-group-checkbox__text">
          Al marcar esta casilla, autorizo el tratamiento de mis datos
          personales de acuerdo con la
          <a
            class="form__legal__link"
            target="_blank"
            href="https://quac.co/legales/politica-proteccion-datos-personales"
          >
            política de privacidad
          </a>
          de <b>Quac</b> y acepto los
          <a
            class="form__legal__link"
            target="_blank"
            href="https://quac.co/legales/tyc"
          >
            términos y condiciones
          </a>
          del crédito. Entiendo que consultar mi historial de crédito es un paso
          indispensable durante el proceso de aprobación.
        </p>
      </div>
      <div>
        <button
          class="btn-soli-cupo w-100"
          :disabled="loadRequestPreap || !checkboxTerms || !validateDocumento"
          @click="sendRequestPreap"
        >
          <div v-if="!loadRequestPreap">
            Solicitar cupo personal
          </div>
          <div v-else class="spinner-border loading-button" role="status"></div>
        </button>
        <div v-if="msgErrorInitPreab" class="text-center mb-3 text-danger">
          {{ msgErrorInitPreab }}
        </div>
      </div>
      <p class="mb-0 text-center px-4" style="line-height: .9">
        <span style="color: #9DA9B9; font-size: 11px;" class="text-center"
          >*Válido únicamente en Colombia. La aprobación inmediata esta sujeta a
          la respuesta oportuna del cliente y sus referencias personales. Es
          requisito ser colombiano, con línea nacional y correo electrónico.
        </span>
      </p>
    </div>
    <div v-if="stepSoli == 1" class="container-modal-soli-cupo mt-3">
      <span
        v-if="stepForm != 3"
        style="position: absolute; top: -1.2rem; left: .5rem; display: flex; align-items: center; cursor: pointer; color: gray;"
        @click="stepForm == 1 ? (stepSoli -= 1) : (stepForm = 1)"
        ><img src="@/assets/icons/flecha-boton.svg" style="width: 30px" />
        Volver</span
      >
      <div v-if="stepForm">
        <div v-if="stepForm == 1" class="px-2">
          <p class="text-center mt-3 px-2">
            Vas iniciar un proceso de solicitud, es necesario que ingreses los
            siguientes datos.
          </p>
          <div class="col-12 position-relative pb-1">
            <input
              v-model="nombres"
              class="payment-input input-login mb-4"
              placeholder="Nombres"
              type="text"
              autocomplete="given-name"
              name="nombres"
              @blur="validateBlur('name')"
              @focus="errorNombre = false"
            />
            <span v-if="errorNombre" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span v-if="nombres" class="newlatter-error-input">Nombre</span>
          </div>
          <div class="col-12 position-relative pb-1">
            <input
              v-model="apellidos"
              class="payment-input input-login mb-4"
              placeholder="Apellidos"
              type="text"
              autocomplete="family-name"
              name="apellidos"
              @blur="validateBlur('lastName')"
              @focus="errorApellidos = false"
            />
            <span v-if="errorApellidos" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span v-if="apellidos" class="newlatter-error-input"
              >Apellidos</span
            >
          </div>
          <div class="col-12 position-relative pb-1">
            <input
              v-model="cc"
              class="payment-input input-login mb-4"
              placeholder="Cédula"
              type="number"
              name="cc"
              @blur="validateBlur('cc')"
              @focus="errorCC = false"
            />
            <span v-if="errorCC" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span v-if="cc" class="newlatter-error-input">Cédula</span>
          </div>
          <div class="col-12 position-relative pb-1">
            <input
              v-model="date"
              class="payment-input input-login mb-4"
              :max="maxDate"
              placeholder="Fecha"
              type="date"
              name="cc"
              @blur="validateBlur('date')"
              @focus="errorDate = false"
            />
            <span v-if="errorDate" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span class="newlatter-error-input">Fecha de expedición</span>
          </div>
          <div v-if="msgError" class="text-center mb-3">
            <span style="font-size: 14px; color: red;">{{ msgError }}</span>
          </div>
          <div>
            <button class="btn-soli-cupo w-100" @click="sendSolicitud()">
              Continuar
            </button>
          </div>
        </div>
        <div v-if="stepForm == 2">
          <p class="text-center px-2">
            Agregas unos datos más, esto servirá para ponernos en contacto
            contigo.
          </p>
          <div class="col-12 position-relative pb-1 d-flex align-items-start">
            <div
              style="min-height: 51px; background-color: #f8f8f8; border-bottom: 1px solid #949494;"
              class="d-flex align-items-center px-3"
            >
              <img src="@/assets/icons/Icon-simple-whatsapp.svg" />
            </div>
            <input
              v-model="celular"
              class="payment-input input-login mb-4"
              placeholder="Celular"
              type="tel"
              autocomplete="tel"
              name="tel"
              @blur="validateBlur('tel')"
              @focus="errorCelular = false"
            />
            <span v-if="errorCelular" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span v-if="celular" class="newlatter-error-input">Celular</span>
          </div>
          <div class="col-12 position-relative pb-1">
            <input
              v-model="correo"
              class="payment-input input-login mb-4"
              placeholder="Correo electrónico"
              type="email"
              autocomplete="email"
              name="email"
              @blur="validateBlur('correo')"
              @focus="errorCorreo = false"
            />
            <span v-if="errorCorreo" class="error-inputs"
              >Este campo es obligatorio</span
            >
            <span v-if="correo" class="newlatter-error-input"
              >Correo electrónico</span
            >
          </div>
          <div class="col-12 position-relative pb-1 mb-4">
            <select
              id="selectedDep"
              v-model="selectedDep"
              class="payment-select w-100"
              name="selectedDep"
              @blur="validateBlur('dep')"
              @focus="campoDepartamentoRequerido = false"
            >
              <option :value="null">Departamento</option>
              <option
                v-for="(item, index) in deps"
                :key="index"
                :value="item.codigo"
              >
                {{
                  item.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </option>
            </select>
            <span v-if="campoDepartamentoRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="selectedDep" class="newlatter-error-input"
              >Tipo de documento</span
            >
          </div>
          <div class="col-12 position-relative pb-1 mb-4">
            <select
              id="selectedDep"
              v-model="selectedCity"
              class="payment-select w-100"
              name="selectedDep"
              @blur="validateBlur('ciu')"
              @focus="campoCiudadRequerido = false"
            >
              <option :value="null">Ciudad</option>
              <option
                v-for="(item, index) in municipiosFiltered"
                :key="index"
                :value="item.codigo"
              >
                {{
                  item.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </option>
            </select>
            <span v-if="campoCiudadRequerido" class="payment-error-label"
              >Este campo es obligatorio</span
            >
            <span v-if="selectedDep" class="newlatter-error-input"
              >Tipo de documento</span
            >
          </div>
          <div class="mb-4 d-flex align-items-center justify-content-center">
            <label class="d-flex my-3 mx-0 ms-md-1">
              <input
                v-model="tyc"
                class="tyc-checkbox"
                type="checkbox"
                checkmark="checkmark"
              />
              <span class="checkmark"></span>
            </label>
            <p class="mb-0 text-start" style="font-size: 1rem">
              Acepto la
              <router-link
                style="font-size: 1rem; color: #654062;"
                target="_blank"
                :to="{ name: 'DataPolice' }"
                >consulta, reporte y tratamiento de datos.</router-link
              >
            </p>
          </div>
          <div v-if="msgError" class="text-center mb-3">
            <span style="font-size: 14px; color: red;">{{ msgError }}</span>
          </div>
          <div>
            <button
              class="btn-soli-cupo w-100"
              @click="
                tyc
                  ? sendSolicitud()
                  : (msgError = 'Acepta los terminos para poder continuar.')
              "
            >
              Continuar
            </button>
          </div>
        </div>
        <div
          v-if="stepForm == 3"
          style="min-height: 460px;"
          class="d-flex flex-wrap justify-content-center align-content-center"
        >
          <img
            src="@/assets/icons/loader-modal-cupo.svg"
            class="loader-modal-cupo"
          />
          <p class="text-center w-100">
            Estamos verificando tus datos, esto <br />
            puede tardar unos minutos, no <br />
            cierres o recargues esta página
          </p>
        </div>
        <div
          v-if="stepForm == 4"
          style="min-height: 460px;"
          class="d-flex flex-wrap justify-content-center"
        >
          <div class="text-center mt-auto">
            <p class="bold">Tu solicitud se ha enviado</p>
            <p class="text-center w-100">
              Revisaremos tus datos, espera nuestra llamada de comprobación de
              información y muy pronto podrás estrenar lo último de la moda.
            </p>
          </div>
          <div class="w-100 mt-auto">
            <button
              class="btn-soli-cupo w-100 mb-0"
              @click="
                showModal = false
                $router.push({ name: 'Home' })
              "
            >
              Volver a la tienda
            </button>
          </div>
        </div>
        <div v-if="stepForm <= 3" class="text-center">
          <img src="@/assets/icons/check-cupo-modal.svg" class="pr-2" />
          <span style="font-size: 11px; color: #9DA9B9"
            >Datos encriptados y protegidos</span
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable */
import carousel from 'v-owl-carousel'
import { mapState, mapActions } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { carousel },
  data() {
    return {
      documento: '',
      loadRequestPreap: false,
      checkboxTerms: false,
      stepSoli: 0,
      stepForm: 1,
      showModal: false,
      nombres: '',
      errorNombre: false,
      apellidos: '',
      errorApellidos: false,
      cc: '',
      errorCC: false,
      date: null,
      errorDate: false,
      errorCiuedad: false,
      correo: '',
      errorCorreo: false,
      celular: '',
      errorCelular: false,
      loading: false,
      msgError: '',
      msgSuccess: '',
      selectedDep: null,
      campoDepartamentoRequerido: false,
      selectedCity: null,
      campoCiudadRequerido: false,
      tyc: false,
      msgErrorInitPreab: ''
    }
  },
  computed: {
    ...mapState('products', ['deps']),
    validateDocumento() {
      return this.documento.length > 4 && this.documento.length <= 15 && !this.documento.includes('e')
    },
    validateNombres() {
      const regex = /^[A-Za-z\s]+$/;
      return this.nombres.length >= 2 && this.nombres.length <= 100 && regex.test(this.nombres)
    },
    validateApellidos() {
      const regex = /^[A-Za-z\s]+$/;
      return this.apellidos.length > 2 && this.apellidos.length <= 100 && regex.test(this.apellidos)
    },
    validateCC() {
      return this.cc.length > 4 && this.cc.length <= 15 && !this.cc.includes('e')
    },
    validateDate() {
      if (this.date != null) {
        let dateSplit = this.date.split('-')
        let dateNow = new Date()
        if (dateNow.getFullYear() > parseInt(dateSplit[0])) {
          return true
        } else if (dateNow.getFullYear() == parseInt(dateSplit[0])) {
          if (dateNow.getMonth() + 1 > parseInt(dateSplit[1])) {
            return true
          } else if (dateNow.getMonth() + 1 == dateSplit[1]) {
            if (dateNow.getDate() > parseInt(dateSplit[2])) {
              return true
            } else if (dateNow.getDate() == dateSplit[2]) {
              return true
            }
          }
        }
      }
      return false
    },
    validateCiuedad() {
      return this.selectedCity
    },
    validateCorreo() {
      this.correo = this.correo.toLowerCase()
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validateCelular() {
      const re = /^3[0-9]{9}/
      return re.test(this.celular) && this.celular.length < 11
    },
    municipiosFiltered: function() {
      var temp = []
      if (this.selectedDep && this.deps.length > 0) {
        temp = this.deps.filter(
          municipio => municipio.codigo == this.selectedDep,
        )[0].ciudades
        if (
          temp.find(element => element.codigo == this.selectedCity) == undefined
        ) {
          this.selectedCity = null
        }
      } else {
        {
          this.selectedCity = null
        }
      }
      return temp
    },
    maxDate() {
      let date = new Date()
      let year = date.getFullYear()
      let month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay()
      return `${year}-${month}-${day}`
    },
  },
  watch: {
    async showModal() {
      if (!this.showModal) {
        this.stepSoli = 0
        this.stepForm = 1
        this.showModal = false
        this.nombres = ''
        this.errorNombre = false
        this.apellidos = ''
        this.errorApellidos = false
        this.cc = ''
        this.errorCC = false
        this.date = null
        this.errorDate = false
        this.errorCiuedad = false
        this.correo = ''
        this.errorCorreo = false
        this.celular = ''
        this.errorCelular = false
        this.loading = false
        this.msgError = ''
        this.msgSuccess = ''
        this.selectedDep = null
        this.campoDepartamentoRequerido = false
        this.selectedCity = null
        this.campoCiudadRequerido = false
      }
    },
    stepForm() {
      if (this.stepForm == 4) {
        sendFBQEvent(this, 'CompleteRegistration', {})
      }
    },
    tyc() {
      if (this.tyc) {
        this.msgError = ''
      }
    },
  },
  async mounted() {
    await this.getDataCitys()
  },
  methods: {
    ...mapActions('products', ['getDataCitys']),
    sendRequestPreap(){
      this.msgErrorInitPreab = ''
      let data = new FormData()
      data.append('documento', this.documento)
      this.loadRequestPreap = true
      this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/quac/preaprobado/solicitud',
        data: data
      })
        .then(response => {
          this.loadRequestPreap = false
          const urlRedirectPreap = response.data;
          window.location.href = urlRedirectPreap
        })
        .catch(err => {
          this.loadRequestPreap = false
          let message = ''
          for (const property in err.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
          if(message == ''){
            message = err.response.data.message
          }
          this.msgErrorInitPreab = message
        })
    },
    validateBlur(input) {
      if (input == 'name') {
        this.errorNombre = !this.validateNombres
      } else if (input == 'lastName') {
        this.errorApellidos = !this.validateApellidos
      } else if (input == 'cc') {
        this.errorCC = !this.validateCC
      } else if (input == 'date') {
        this.errorDate = !this.validateDate
      } else if (input == 'tel') {
        this.errorCelular = !this.validateCelular
      } else if (input == 'correo') {
        this.errorCorreo = !this.validateCorreo
      } else if (input == 'dep') {
        this.campoDepartamentoRequerido = !this.selectedDep
      } else if (input == 'ciu') {
        this.campoCiudadRequerido = !this.selectedCity
      } else {
        return false
      }
    },
    async sendSolicitud() {
      if (
        (this.stepForm == 1 &&
          this.validateNombres &&
          this.validateApellidos &&
          this.validateCC &&
          this.validateDate) ||
        (this.stepForm == 2 &&
          this.validateCelular &&
          this.validateCorreo &&
          this.validateCiuedad) ||
        this.stepForm > 2
      ) {
        let tempStpe = this.stepForm
        this.msgError = ''
        this.stepForm = 3
        let data = new FormData()
        data.append('nombres', this.nombres)
        data.append('apellidos', this.apellidos)
        data.append('documento', this.cc)
        data.append('fecha_expedicion', this.date)
        if (tempStpe != 1) {
          data.append('step', 2)
          data.append('celular1', this.celular)
          data.append('correo1', this.correo)
          data.append('documento', this.cc)
          data.append('ciudad', this.selectedCity)
          data.append('departamento', this.selectedDep)
        } else {
          data.append('step', 1)
        }
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cupopersonal/registrarSolicitudExterna',
          data: data,
        })
          .then(() => {
            if (tempStpe == 2) {
              tempStpe = 4
            } else {
              tempStpe += 1
            }
          })
          .catch(e => {
            console.log(e.response)
            let message = ''
            for (const property in e.response.data.errors) {
              message = message + e.response.data.errors[property] + ' '
            }
            this.msgError = message
            this.stepForm = tempStpe
          })
          .finally(() => {
            this.stepForm = tempStpe
          })
      } else {
        if (this.stepForm == 1) {
          this.errorNombre = !this.validateNombres
          this.errorApellidos = !this.validateApellidos
          this.errorCC = !this.validateCC
          this.errorDate = !this.validateDate
        } else {
          this.errorNombre = !this.validateNombres
          this.errorApellidos = !this.validateApellidos
          this.errorCC = !this.validateCC
          this.errorCiuedad = !this.validateCiuedad
          this.errorCorreo = !this.validateCorreo
          this.errorCelular = !this.validateCelular
          this.campoDepartamentoRequerido = !this.selectedDep
          this.campoCiudadRequerido = !this.validateCiuedad
        }
      }
    },
  },
}
</script>
<style scoped>
.title-modal-soli-cupo {
  color: #7633ff !important;
}
.form__legal .form-group-checkbox__text{
  font-size: .8rem;
  color: #707070;
  line-height: 15px;
}
.form-group-checkbox__text {
  text-align: left;
}
.form-group-checkbox {
  display: flex;
  align-items: center;
}
.input-checkbox input[type=checkbox]:checked, .input-checkbox input[type=radio]:checked {
  background-color: #802EFF !important;
}
.input-checkbox input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border: 1px solid #a19eb5;
  border-radius: 0.5em;
  background-color: white;
}
.input-checkbox {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: #F4F6FB;
  border-radius: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group-checkbox{
  align-items: start;
}
.form-group-checkbox .input-checkbox{
  margin-top: 1rem;
  margin-right: 1rem;
}
.form-group {
 width: 100%;
}
.input-group {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.form-group__label {
  display: block;
  text-align: left;
  color: #374957;
  font-weight: bold;
}
.input-group__prepend {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4F6FB;
  border: 1px solid #a19eb5;
  border-right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.input-group__text {
  font-weight: 700;
  white-space: nowrap;
  padding: 0 1rem;
  text-align: center;
  color: #2e1380;
}
.input-group__prepend~.input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.input {
  flex: 1 1 auto;
  font-family: inherit;
  font-size: inherit;
  padding: 0.8rem;
  border: 1px solid #a19eb5;
  border-radius: 10px;
  outline: none;
  width: 100%;
}
</style>
