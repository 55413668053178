export default {
  menuDerek: null,
  categorysList: null,
  outfitsVitrina: null,
  bannerHome: null,
  vitrinasHome: [],
  menuLayoutDerek: '',
  footerLayoutDerek: '',
  topbar: null,
}
