var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sub-menu-desktop"},[_c('div',{staticClass:"container-max-width d-flex w-100"},[_c('div',{staticClass:"col-12 col-lg-6 col-xl-5 d-flex"},[_c('div',{staticClass:"pt-3 w-100"},[_c('h2',{staticClass:"bold"},[_vm._v(" "+_vm._s(_vm.category.toLowerCase().replace(/^\w/, d => d.toUpperCase()))+" ")]),(_vm.dataSubMenu)?_c('div',{staticClass:"categorys-submenu"},[_c('span',{staticClass:"d-block"},[_c('router-link',{attrs:{"to":{
                  name: 'TiendaCategory',
                  params: {
                    category: _vm.category,
                    nameCreative: `menu-${_vm.category}`,
                    slot: `ver todo menu`,
                    id: `${_vm.category}`,
                    name: `menu ${_vm.category}`,
                  },
                }}},[_vm._v(" Ver todo de "+_vm._s(_vm.category.toLowerCase().replace(/^\w/, d => d.toUpperCase()))+" ")])],1),_vm._l((_vm.dataSubMenu),function(item,index){return _c('span',{key:index,staticClass:"d-block"},[_c('router-link',{attrs:{"to":{
                  name: item.routerName,
                  params: {
                    category: _vm.category,
                    subcategory: item.slug,
                    nameCreative: `menu-${item.slug}`,
                    slot: `menu`,
                    id: `${item.slug}`,
                    name: `menu ${item.slug}`,
                  },
                  query: item.query ? { [item.query]: item.slug } : {},
                }}},[_vm._v(" "+_vm._s(item.descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" "),(item.destacado == 1)?_c('img',{staticClass:"novelity",attrs:{"src":require("@/assets/icons/novelty-icon.svg"),"alt":item.descripcion}}):_vm._e()])],1)})],2):_vm._e()]),_c('div',{staticClass:"divider d-none d-lg-block"})]),_c('div',{staticClass:"d-none d-lg-block col-lg-6 col-xl-7 pt-3"},[_c('div',{staticClass:"decoration-back-menu",staticStyle:{"background-color":"#FBEEE3"}}),_c('div',{staticClass:"container-baner-img-menu"},_vm._l((_vm.categoriasDestacadas),function(item,index){return _c('router-link',{key:index,attrs:{"to":{
              name: item.routerName,
              params: {
                category: _vm.category,
                subcategory: item.slug,
                nameCreative: `menu-${item.slug}`,
                slot: `menu`,
                id: `${item.slug}`,
                name: `menu ${item.slug}`,
              },
            }}},[_c('img',{staticStyle:{"width":"100%","max-width":"300px"},attrs:{"src":item.img,"alt":item.name}}),_c('div',{staticClass:"ps-5 pe-2"},[_c('h3',{staticClass:"title-banner-submenu-2"},[_vm._v(" "+_vm._s(item.descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")])])])}),1)])])]),_c('div',{staticClass:"modal-with-hover"})])
}
var staticRenderFns = []

export { render, staticRenderFns }