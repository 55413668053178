import Vue from 'vue'
// import $store from '@/store'

// ---||  [ Obtener el menu ]  ||--- //
export function getMenuDerek({ state }) {
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/layouts/get_menu_derek',
  })
    .then(response => {
      state.menuDerek = response.data.items
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('GetMenuDerek')
    })
}

// ---||  [ Get Topbar ]  ||--- //
export function getTopbar({ state }) {
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/layouts/get_topbar_derek',
  })
    .then(response => {
      state.topbar = response.data.topbar
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('GetMenuDerek')
    })
}

// ---||  [ Obtener las categorias del home ]  ||--- //
export async function getCategorys({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/get_categories',
  })
    .then(response => {
      state.categorysList = response.data.categories
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('GetCategorys')
    })
}

// ---||  [ Obtener home ]  ||--- //
export async function getHome({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/get_home',
  })
    .then(response => {
      state.outfitsVitrina = response.data.home.vitrina_outfits
      state.categorysList = response.data.home.categories
      state.bannerHome = response.data.home.banner
      state.vitrinasHome = response.data.home.vitrinas
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('GetCategorys')
    })
}

// ---||  [ Cambiar el menu ]  ||--- //
export function changeMenu({ state }, payload) {
  state.menuLayoutDerek = payload.menu
}
// ---||  [ Cambiar el footer ]  ||--- //
export function changeFooter({ state }, payload) {
  state.footerLayoutDerek = payload.footer
}
