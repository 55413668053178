import Vue from 'vue'
import $store from '../../store/index'
import $router from '../../router/index'

// ---||  [ Obtener la Store ]  ||--- //
export function getProductsCategory({ state }, payload) {
  $store.state.loaderDerek = true
  state.productsCategory = null
  state.productsAnalytics = []
  let urlSlug =
    payload.category +
    (payload.subcategory ? '-' + payload.subcategory : '') +
    (payload.wildCard ? '-' + payload.wildCard : '')
  if (!urlSlug) {
    urlSlug = 'store'
  }
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/products/catalogue/' + urlSlug + payload.query,
  })
    .then(response => {
      state.productsCategory = response.data.productos
      state.productStrategia = response.data.estrategia_venta
      state.productsAnalytics = response.data.productos_analytics
      state.dataCatalogoAnalytics = response.data.data_subcategorias_analytics
      state.titleStore = response.data.info_catalogo.titulo
      document.title =
        response.data.info_catalogo.titulo + ' | DEREK Tienda Online'
      state.descriptionStore = response.data.info_catalogo.descripcion
    })
    .catch(e => {
      if (e.response.status == 404) {
        $router.replace({
          name: 'Error',
          params: { path: $router.history._startLocation.replace('/', '') },
        })
      }
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('getProductsCategory')
    })
}

// ---||  [ Obtener los filtros de Store ]  ||--- //
export function getFiltersCategory({ state }, payload) {
  state.atajos = []
  $store.state.loaderDerek = true
  state.productsCategory = null
  state.loaderFilters = true
  let urlSlug =
    payload.category +
    (payload.subcategory ? '-' + payload.subcategory : '') +
    (payload.wildCard ? '-' + payload.wildCard : '')
  if (!urlSlug) {
    urlSlug = 'store'
  }
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/searchs/filters_catalogue/' + urlSlug + payload.query,
  })
    .then(response => {
      state.loaderFilters = false
      state.atajos = response.data.atajos
      state.filtersColor = response.data.colores
      state.filtersPrecio = [
        response.data.precios[0].min,
        response.data.precios[0].max,
      ]
      state.filtersSize = response.data.tallas
      state.filtersCategoria = response.data.categorias
      state.filtersSubcategoria = response.data.subcategorias
      state.filtersDiscounts = response.data.descuentos
      state.filtersMultiSelect = response.data.filtros_simples
      // state.filtersOneLevel = response.data.filtros_unico
      state.filterFeature = response.data.destacados
      state.filterCollections = response.data.colecciones
      state.tallas_todo = response.data.tallas_todo
      state.subcategorias_todo = response.data.subcategorias_todo
      state.colores_todo = response.data.colores_todo
      state.endLoadFilters += 1
      state.allFilters = response.data
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      state.loaderFilters = false
      console.log('getFiltersCategory')
    })
}

// ---||  [ Obtener la data del producto con la url ]  ||--- //
export function getDataProduct({ state }, payload) {
  $store.state.loaderDerek = true
  state.dataProduct = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url:
      '/products/sku/' +
      (payload.producto_sku ? payload.producto_sku : payload.url) +
      (window.localStorage.getItem('tokenCart')
        ? '?token_cart=' + window.localStorage.getItem('tokenCart')
        : ''),
  })
    .then(response => {
      state.dataProduct = response.data.producto
    })
    .catch(e => {
      if (e.response.status == 404) {
        console.log($router.history._startLocation)
        $router.replace({
          name: 'Error',
          params: { path: $router.history._startLocation.replace('/', '') },
        })
      }
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('getDataProduct')
    })
}

// ---||  [ Obtener el outfit de un producto ]  ||--- //
export function getOutfitProductDetail({ state }, payload) {
  $store.state.loaderDerek = true
  state.outfitProduct = null
  state.outfitAnalitics = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/products/outfit/sku/' + payload.sku,
  })
    .then(response => {
      state.outfitAnalitics = response.data.outfit.productos_analytics
      state.outfitProduct = response.data.outfit.productos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('getOutfit')
    })
}

// ---||  [ Obtener productos relacionados ]  ||--- //
export async function sendComment({ state }, payload) {
  state.productRelated = null
  state.errorSendComment = false
  $store.state.loaderDerek = true
  var data = new FormData()
  data.append('puntaje', parseInt(payload.cal))
  data.append('comentario', payload.text)
  data.append('producto_sku', payload.producto_sku)
  data.append('correo', payload.email)
  data.append('nombre', payload.name)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/ratings/new',
    data: data,
  })
    .then(function(response) {
      console.log(response.data)
    })
    .catch(e => {
      console.log(e)
      state.errorSendComment = true
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('addProduct Done')
    })
}

// ---||  [ Obtener productos relacionados ]  ||--- //
export async function getProductsRelated({ state }, payload) {
  state.productRelated = null
  state.productsAnalyticsRelated = null
  var form = new FormData()
  form.append('skus', payload.sku)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/products/related',
    data: form,
  })
    .then(function(response) {
      state.productsAnalyticsRelated = response.data.productos_analytics
      state.productRelated = response.data.productos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

// ---||  [ Obtener disponibilidad en tienda ]  ||--- //
export function postShippingProduct({ state }, payload) {
  // $store.state.loaderDerek = true;
  state.productStock = null
  let data = new FormData()
  data.append('producto_sku', payload.sku)
  data.append('color', payload.color)
  data.append('talla', payload.talla)
  data.append('ciudad_codigo', payload.city ? payload.city : '')
  Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/products/warehouses/available',
    data: data,
  })
    .then(response => {
      if (response.data.ciudades) {
        console.log('Entre a ciudades')
        state.stockCitys = response.data.ciudades
      }
      if (response.data.bodegas) {
        console.log('Entre a bodegas')
        state.productStock = response.data.bodegas
      }
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      // $store.state.loaderDerek = false;
      console.log('getStock')
    })
}

// ---||  [ Obtener los departamentos y ciudades ]  ||--- //
export async function getDataCitys({ state }) {
  await Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/departments/shippings',
  })
    .then(response => {
      state.deps = response.data.departamentos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('getDataCitys')
    })
}

// ---||  [ Calcular envio ]  ||--- //
export async function calculateShipping({ state }, payload) {
  if (payload.departamento_codigo != null && payload.ciudad_codigo != null) {
    var form = new FormData()
    form.append('departamento_codigo', payload.departamento_codigo)
    form.append('ciudad_codigo', payload.ciudad_codigo)
    await Vue.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/shippings/calculate_shipping',
      data: form,
    })
      .then(function(response) {
        if (response.data.envio != null) {
          state.shippingsTotal = response.data.envio
        } else {
          state.shippingsTotal = 'No disponible'
        }
      })
      .catch(e => {
        console.log(e)
      })
      .finally(() => {
        console.log('getDataCitys')
      })
  }
}

// ---||  [ Añadir producto a favoritos ]  ||--- //
export async function addToFavorite({ state }, payload) {
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  form.append('producto_sku', payload.sku)
  form.append('color', payload.color)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/whislist/add_product',
    data: form,
  })
    .then(function(response) {
      window.localStorage.setItem('tokenFavorite', response.data.whislist.token)
      state.productWhislist = response.data.productos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

// ---||  [ Eliminar producto de favoritos ]  ||--- //
export async function deleteFromFavorite({ state }, payload) {
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  form.append('producto_sku', payload.sku)
  form.append('color', payload.color)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/whislist/delete_product',
    data: form,
  })
    .then(function(response) {
      if (response.data.productos) {
        window.localStorage.setItem(
          'tokenFavorite',
          response.data.whislist.token,
        )
        state.productWhislist = response.data.productos
      } else {
        state.productWhislist = []
      }
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}

// ---||  [ Obtener productos favoritos ]  ||--- //
export async function getProductsFavorites({ state }) {
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url:
      '/whislist/get_products' +
      (window.localStorage.getItem('tokenCart')
        ? '?token_cart=' + window.localStorage.getItem('tokenCart')
        : ''),
    data: form,
  })
    .then(function(response) {
      state.productWhislist = response.data.productos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('addProduct Done')
    })
}
// ---||  [ Eliminar producto favoritos ]  ||--- //
export async function deleteProductFavorite({ state }, payload) {
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenFavorite')
      ? window.localStorage.getItem('tokenFavorite')
      : '',
  )
  form.append('producto_sku', payload.producto_sku)
  form.append('color', payload.color)
  await await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/whislist/delete_product',
    data: form,
  })
    .then(function(response) {
      if (response.data.productos) {
        state.productWhislist = response.data.productos
      } else {
        state.productWhislist = []
        window.localStorage.removeItem('tokenFavorite')
      }
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('deleteProduct Done')
    })
}
