export default {
  productsCart: null,
  dataCart: null,
  productFeatured: null,
  relatedAnalytics: null,
  productStrategy: null,
  msgErrorAddProduct: '',
  updateValidations: 0,
  validateDataUser: false,
  validateAddres: false,
  dataSummaryPayment: null,
  errorPaymentBillBalotoEfecty: '',
  errorPaymentBillPSE: '',
  msgErrorCheckout: '',
  loadingBag: false,
  errorPSE: '',
  errorCreditCard: '',
  msgErrorCupon: '',
  urlPayPSE: '',
  faltanCampos: false,
  urlPayAddi: '',
  errorAddi: '',
  ////////////////////////////
  dataCupo: null,
  errorCupo: false,
  msgErrorCupo: '',
  ////////////////////////////
  msgModalExcepcion406: '',
  btnModalExcepcion406: '',
  errorSurvey: false,
  ///////////////////////////
}
