<template>
  <div>
    <div class="sub-menu-desktop">
      <div class="container-max-width d-flex w-100">
        <div class="col-12 col-lg-6 col-xl-5 d-flex">
          <div class="pt-3 w-100">
            <h2 class="bold">
              {{ category.toLowerCase().replace(/^\w/, d => d.toUpperCase()) }}
            </h2>
            <div v-if="dataSubMenu" class="categorys-submenu">
              <span class="d-block">
                <router-link
                  :to="{
                    name: 'TiendaCategory',
                    params: {
                      category: category,
                      nameCreative: `menu-${category}`,
                      slot: `ver todo menu`,
                      id: `${category}`,
                      name: `menu ${category}`,
                    },
                  }"
                >
                  Ver todo de
                  {{
                    category.toLowerCase().replace(/^\w/, d => d.toUpperCase())
                  }}
                </router-link>
              </span>
              <span
                v-for="(item, index) in dataSubMenu"
                :key="index"
                class="d-block"
              >
                <router-link
                  :to="{
                    name: item.routerName,
                    params: {
                      category: category,
                      subcategory: item.slug,
                      nameCreative: `menu-${item.slug}`,
                      slot: `menu`,
                      id: `${item.slug}`,
                      name: `menu ${item.slug}`,
                    },
                    query: item.query ? { [item.query]: item.slug } : {},
                  }"
                >
                  {{
                    item.descripcion
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}
                  <img
                    v-if="item.destacado == 1"
                    class="novelity"
                    src="@/assets/icons/novelty-icon.svg"
                    :alt="item.descripcion"
                  />
                </router-link>
              </span>
            </div>
          </div>
          <div class="divider d-none d-lg-block" />
        </div>
        <div class="d-none d-lg-block col-lg-6 col-xl-7 pt-3">
          <div
            class="decoration-back-menu"
            style="background-color: #FBEEE3;"
          />
          <div class="container-baner-img-menu">
            <router-link
              v-for="(item, index) in categoriasDestacadas"
              :key="index"
              :to="{
                name: item.routerName,
                params: {
                  category: category,
                  subcategory: item.slug,
                  nameCreative: `menu-${item.slug}`,
                  slot: `menu`,
                  id: `${item.slug}`,
                  name: `menu ${item.slug}`,
                },
              }"
            >
              <img
                style="width: 100%; max-width: 300px"
                :src="item.img"
                :alt="item.name"
              />
              <div class="ps-5 pe-2">
                <h3 class="title-banner-submenu-2">
                  {{
                    item.descripcion
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}
                </h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-with-hover" />
  </div>
</template>
<script>
export default {
  props: {
    dataSubMenu: {
      type: Array,
      required: false,
      default: () => [],
    },
    category: {
      type: String,
      required: false,
      default: '',
    },
    categoriasDestacadas: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      subMenu: [
        { name: 'Blusas', slug: '', tag: '' },
        { name: 'Faldas', slug: '', tag: '' },
        { name: 'Ropa Interior', slug: '', tag: '' },
        { name: 'Buzos', slug: '', tag: '' },
        { name: 'Jeans', slug: '', tag: '' },
        { name: 'Shorts', slug: '', tag: '' },
        { name: 'Cardigans', slug: '', tag: 'si' },
        { name: 'Medias', slug: '', tag: 'si' },
        { name: 'Tops', slug: '', tag: '' },
        { name: 'Chalecos', slug: '', tag: '' },
        { name: 'Overoles', slug: '', tag: '' },
        { name: 'Traje de Baño', slug: '', tag: '' },
        { name: 'Chaquetas', slug: '', tag: '' },
        { name: 'Pantalones', slug: '', tag: '' },
        { name: 'Vestidos', slug: '', tag: 'si' },
        { name: 'Enterizos', slug: '', tag: '' },
        { name: 'Pijamas', slug: '', tag: '' },
      ],
      imgsBanerSubmenu: [
        {
          img: '../assets/Banner_menu_1.jpg',
          name: 'Bufandas',
          subName: 'unicolor',
          slug: '',
        },
        {
          img: '../assets/Banner_menu_2.jpg',
          name: 'Buzos',
          subName: 'casual',
          slug: '',
        },
        {
          img: '../assets/Banner_menu_3.jpg',
          name: 'Chaquetas',
          subName: 'invierno',
          slug: '',
        },
      ],
    }
  },
}
</script>
