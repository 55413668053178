import Vue from 'vue'
import $store from '../../store/index'

// ---||  [ Obtener Categorias de Outfits ]  ||--- //
export function getCategoryOutfits({ state }, payload) {
  $store.state.loaderDerek = true
  state.outfitCategorys = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/outfits/filters' + payload.url,
  })
    .then(response => {
      state.outfitCategorys = response.data.campanias
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('getCategoryOutfits')
    })
}
// ---||  [ Obtener Catalogo de Outfits ]  ||--- //
export function getCatalogueOutfits({ state }, payload) {
  $store.state.loaderDerek = true
  state.dataOutfitPage = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/outfits/catalogue' + payload.url,
  })
    .then(response => {
      state.dataOutfitPage = response.data.outfits
      state.outfitCategorys = response.data.campanias
      state.outfitTitle = response.data.title
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('getCategoryOutfits')
    })
}
// ---||  [ Obtener Detalle de un Outfit ]  ||--- //
export function getDetailOutfit({ state }, payload) {
  $store.state.loaderDerek = true
  state.outfitDetail = null
  Vue.axios({
    method: 'GET',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/outfits/' + payload.id,
  })
    .then(response => {
      state.outfitDetail = response.data.outfit
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('getDetailOutfit')
    })
}
